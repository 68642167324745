@tailwind base;
@tailwind components;
@tailwind utilities;

/* Basic editor styles */

.ProseMirror {
  outline: none;
  border: solid 1px #d2d6da;
  padding: 8px;
}

.tiptap {
  :first-child {
    margin-top: 0;
  }
  padding: 3px;
  height: 104px;
  overflow-y: auto;
  font-size: 14px;
}
/* List styles */
.tiptap {
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
  }
}
.tiptap {
  ol li p {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
}

/* Heading styles */
.tiptap {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }
}
.tiptap {
  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }
}
.tiptap {
  h1 {
    font-size: 1.4rem;
  }
}
.tiptap {
  h2 {
    font-size: 1.2rem;
  }
}
.tiptap {
  h3 {
    font-size: 1.1rem;
  }
}
.tiptap {
  h4,
  h5,
  h6 {
    font-size: 1rem;
  }
}
.tiptap-container {
  overflow: hidden;

  outline: none;
  border-radius: 6px;

  :focus {
    border: solid 2px #1f76e9 !important;
    border-radius: 6px;
  }
}
/* Code and preformatted text styles */
.tiptap {
  code {
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 0.4rem;
    color: black;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }
}
.tiptap {
  pre {
    background: rgb(228, 228, 228);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: "JetBrainsMono", monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;
  }
}
.tiptap {
  pre code {
    background: none;
    color: inherit;
    font-size: 0.8rem;
    padding: 0;
  }
}

.tiptap {
  blockquote {
    margin: 1.5em 10px;
    padding: 0.5em 10px;
  }
  blockquote:before {
    color: rgb(26, 115, 232);
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

  blockquote p {
    display: inline;
    font-weight: 700;
  }
}
.tiptap {
  hr {
    border: none;
    border-top: 1px solid gray;
    margin: 2rem 0;
  }
}

.is-active {
  position: relative;
  color: rgb(26, 115, 232);
}

.button-group {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 5px;
  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    font-size: 24px;
    border: none;
    border-radius: 5px;
    :hover {
      background-color: rgba(26, 115, 232, 0.2);
    }
  }
}
